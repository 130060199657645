import React from 'react';
import './App.css';
import ShowCard from './components/ShowCard';



function App() {

  return (
    <div className="App">
      <header className="App-header" >
        <ShowCard />        
      </header>
    </div>
  );
}

export default App;
