import React from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { useState } from 'react'
// Add link to correct pdf if it is received by the client
// import pdf from '../testpdf.pdf'


function ShowButtons() {

    const [show1, setShowOne] = useState(false);

    const handleCloseOne = () => setShowOne(false);
    const handleShowOne = () => setShowOne(true);
  
    const [show2, setShowTwo] = useState(false);
  
    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);

    return(
        <div className='Button-Group'>
        <ButtonGroup size='md' className='mb-3 d-flex' vertical='true'>
          <Button variant="dark" onClick={handleShowOne}>Dealer Inquiry</Button>
          <Modal show={show1} onHide={handleCloseOne} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Scorpion Automotive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please direct any questions to mark.fensome@scorpionauto.com
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseOne}>Close</Button>
              <Button variant="primary" onClick={() => window.location = 'mailto:mark.fensome@scorpionauto.com'}>Send Email</Button>
            </Modal.Footer>
          </Modal>
        </ButtonGroup>
        <ButtonGroup size='md' className='mb-3 d-flex' vertical='true'>
          <Button variant="dark" onClick={handleShowTwo}>More Info</Button>
          <Modal show={show2} onHide={handleCloseTwo} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Scorpion Automotive</Modal.Title>
             </Modal.Header>
            <Modal.Body>
              As the #1 vehicle security provider in the UK, Scorpion Automotive is proud to announce the launch of DEFENDER in the US
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseTwo}>Close</Button>

              {/* Add in this button if we receive pdf from client */}
              {/* <Button variant="primary" href={pdf} target='_blank' rel="noreferrer" >Details</Button> */}
            </Modal.Footer>
          </Modal>
        </ButtonGroup>
      </div>
    );
}

export default ShowButtons;



