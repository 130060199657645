import React from 'react';
import ScorpionAutoBlack from '../ScorpionAutoBlack.png';
import redCorvette from '../redCorvette.png';
import Defender from '../Defender.jpeg'
import { Card, Image } from 'react-bootstrap';
import ShowButtons from './ShowButtons';


function ShowCard() { 

  return (
    <Card style={{width: '85%', border: 'none', paddingTop: '10%'}}>
    <Card.Img variant='top' as={Image} 
              fluid={true} src={ScorpionAutoBlack}
              className="App-logo" alt="logo"></Card.Img>
    <Card.Img variant='top' as={Image} 
             fluid={true} src={redCorvette}
             className="Red-Corvette" alt="logo"></Card.Img>

    <Card.Body>
      <Card.Text>
        The return of vehicle security
      </Card.Text>
      <br/>
      <ShowButtons />
      </Card.Body>
      <Card.Img variant='top' as={Image} 
                fluid={true} src={Defender} 
                className="Red-Corvette" alt="logo">
      </Card.Img>
      <br/>
      <Card.Footer style={{background: 'white', border: 'none', paddingTop: '10%'}}>&copy; 2022 Copyright Scorpion Automotive US, LLC</Card.Footer>
  </Card>
  );
    
}

export default ShowCard;
